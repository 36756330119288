import request from '@/utils/request'

/**
 * 生成小程序码
 * @param wxQrCodeUnlimited
 * @returns base64
 */
export function qr() {
  return request({
    url: '/bindingQr',
    method: 'post'
  })
}

// h5跳转小程序 生成小程序URL Scheme
export function generateScheme(param) {
  return request({
    url: '/wechat/generatescheme',
    method: 'post',
    data: JSON.stringify(param)
  })
}

// h5跳转小程序 生成小程序URL Scheme
export function generateSSOScheme(univercityCode,param) {
  return request({
    url: '/sso/login/applet/'+univercityCode,
    method: 'get',
    params:  param    
  })
}


